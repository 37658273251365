import {createRouter, createWebHistory} from 'vue-router';
import {useStore} from "./store/store_pinia";

import Ok from "./pages/Ok.vue";

const checkNotAuth = (to, from, next) => {
	if (useStore().isAuth) {
		return next('/match');
	}
	next();
};

const routes = [
	{
		name: 'register',
		path: '/:pk?',
		component: () => import('./pages/Register.vue'),
		beforeEnter: checkNotAuth,
		meta: {
			layout: "bg--register",
		},
	},
	{
		name: 'login',
		path: '/login',
		component: () => import('./pages/Login.vue'),
		meta: {
			layout: "bg--login",
		},
		beforeEnter: checkNotAuth,
	},
	{
		name: 'chat',
		path: '/chat/:pk?',
		component: () => import('./pages/Chat.vue'),
		meta: {
			layout: "bg--chat",
		},
		beforeEnter: checkNotAuth,
	},
	{
		name: 'unsub',
		path: '/unsub',
		component: () => import('./pages/Unsub.vue'),
		meta: {
			layout: "bg--unsub",
		}
	},
	{
		name: '404',
		path: '/:pathMatch(.*)*',
		component: () => import('./pages/404.vue'),
		meta: {
			layout: "bg--404",
		}
	},
	{
		name: 'ok',
		path: '/ok',
		component: Ok,
		meta: {
			layout: "bg--ok",
			auth: true,
		},
	},
	{
		name: 'fillform',
		path: '/fillform',
		component: () => import('./pages/FillForm.vue'),
		meta: {
			layout: "bg--fill",
			permission: false,
		},
	},
	{
		name: 'terms',
		path: '/terms',
		component: () => import('./pages/Terms.vue'),
		meta: {
			layout: "bg--terms",
		}
	},
	{
		name: 'contacts',
		path: '/contacts',
		component: () => import('./pages/Contact.vue'),
		meta: {
			layout: "bg--terms",
		}
	},
	{
		name: 'price',
		path: '/price',
		component: () => import('./pages/Price.vue'),
		meta: {
			layout: "bg--terms",
		}
	},
	{
		name: 'privacy',
		path: '/privacy',
		component: () => import('./pages/Privacy.vue'),
		meta: {
			layout: "bg--terms",
		}
	},
	{
		name: 'permission',
		path: '/permission',
		component: () => import('./pages/Permission.vue'),
		meta: {
			layout: "bg--terms",
		}
	},
	{
		name: 'profile',
		path: '/profile',
		component: () => import('./pages/Profile.vue'),
		meta: {
			layout: "bg--main",
			auth: true,
		},
	},
	{
		name: 'match',
		path: '/match',
		component: () => import('./pages/Main.vue'),
		meta: {
			layout: "bg--main",
			auth: true,
		},
	},
	{
		name: 'message',
		path: '/message',
		component: () => import('./pages/Message.vue'),
		props: true,
		meta: {
			layout: "bg--main",
			auth: true,
		},
	},
	{
		name: 'order',
		path: '/order/:pk?',
		component: () => import('./pages/Order.vue'),
		meta: {
			layout: "bg--orders",
		},
	},
	{
		name: 'preOrder',
		path: '/pre-order/:pk?',
		component: () => import('./pages/PreOrder.vue'),
		meta: {
			layout: "bg--order",
		},
	},
	{
		name: 'fav',
		path: '/fav',
		component: () => import('./pages/Fav.vue'),
		meta: {
			layout: "bg--main",
			auth: true,
		},
	},
	{
		name: 'not',
		path: '/not',
		component: () => import('./components/NotUsersComponent.vue'),
		meta: {
			layout: "bg--main",
		}
	},
	{
		name: 'finder',
		path: '/finder',
		component: () => import('./pages/Fake.vue'),
		meta: {
			layout: "bg--main",
			isLink: false,
		}
	},
	{
		name: 'userFav',
		path: '/user-fav/:id',
		component: () => import('./pages/User.vue'),
		meta: {
			layout: "bg--main",
			auth: true,
		}
	},
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.auth)) {
		if (useStore().isAuth) {
			if (!useStore().isUnsub) {
				return next('/unsub');
			}
			return next();
		}
		return next('/login');
	}
	next();
});

export default router;